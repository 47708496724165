import clsx from "clsx";
import { forwardRef } from "react";
import { clsxm } from "src/utils/clsxWithTailwindMerge";
import { WithTestID } from "src/utils/withTestID";
import { Spinner } from "../Spinner";

type ButtonProps = {
  variant?:
    | "white"
    | "primary"
    | "outline"
    | "loading"
    | "fullWidth"
    | "ghost"
    | "plain"
    | "plainDark"
    | "danger"
    | "pink"
    | "pink2"
    | "grey5";
  type?: "submit" | "reset";
  fullWidth?: true;
  text: string;
  onClick?: () => void;
  onSubmit?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  className?: string;
  spinnerClassName?: string;
  icon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  disabled?: boolean;
};

const BaseButtonClasses = [
  "flex shrink-0 items-center justify-center text-gray-900",
  "border rounded-md",
  "px-4 py-2",
  "font-medium",
  "transition-all duration-standard ease-in-out",
  "cursor-pointer",
];

const ButtonVariants: { [key: string]: string } = {
  danger:
    "bg-red-50 border-red-300 hover:bg-red-100/80 active:bg-red-100 hover:border-red-400 focus:ring-red-300",
  white:
    "bg-white border-gray-200 hover:bg-gray-100/80 active:bg-gray-100 hover:border-gray-300 focus:ring-gray-300",
  primary:
    "bg-gray-100 border-gray-100 hover:bg-gray-200/80 active:bg-gray-200 hover:border-gray-200 focus:ring-gray-500",
  outline: "bg-gray-50 border border-gray-500/25 hover:bg-white active:bg-white ",
  ghost: "bg-gray-50 hover:bg-gray-100 active:bg-gray-200",
  pink6: "bg-pink6 rounded-[20px] font-light text-white tracking-[-0.4px]",
  pink2: "bg-pink2 rounded-[20px] font-light text-white tracking-[-0.4px]",
  grey5: "bg-grey5 rounded-[20px] font-light text-black tracking-[-0.4px]",
  fullWidth: "w-full",
  isLoading: "gap-0.5",
  plain: "border-none",
  disabled: "cursor-not-allowed opacity-50",
};

const getButtonClasses = ({
  variants,
  className,
}: {
  variants?: Array<keyof typeof ButtonVariants>;
  className?: string;
}) => {
  const variantClasses = variants?.map((variant) => (variant ? ButtonVariants[variant] : "")) || [];

  return clsxm(BaseButtonClasses, variantClasses, className);
};

export const Button = forwardRef<HTMLButtonElement, WithTestID<ButtonProps>>(
  (
    {
      text,
      icon,
      leftIcon,
      spinnerClassName,
      variant,
      type,
      fullWidth,
      isLoading,
      loadingText,
      onClick,
      className,
      testID,
      disabled,
      ...props
    },
    forwardedRef,
  ) => {
    if (isLoading === true) {
      return (
        <button
          className={getButtonClasses({
            variants: [variant ?? "", fullWidth ? "fullWidth" : "", "isLoading"],
            className,
          })}
          ref={forwardedRef}
          type={type}
          {...props}>
          <Spinner className={clsx("mr-2", spinnerClassName)} />
          {loadingText ?? "Loading..."}
        </button>
      );
    }

    return (
      <button
        className={getButtonClasses({
          variants: [
            variant ?? "",
            fullWidth ? "fullWidth" : "",
            isLoading ? "isLoading" : "",
            disabled ? "disabled" : "",
          ],
          className,
        })}
        onClick={onClick}
        ref={forwardedRef}
        type={type}
        data-test-id={testID}
        disabled={disabled}
        {...props}>
        {leftIcon}
        {text}
        {icon}
      </button>
    );
  },
);
