import Link from "next/link";
import { useContext, useState } from "react";
import { Settings } from "react-feather";
import { DropdownMenu } from "../DropdownMenu";
import { IconButton } from "../IconButton";
import { Logo } from "../Logo";
import { NavbarLink } from "./NavbarLink";
import { useActiveClinicianContext } from "src/context/ActiveClinicianContext";
import { Region, RegionContext } from "../../../data/auth/region";

function RegionDisplay({ region, email }: { region: Region; email?: string }) {
  return (
    <span title={email} className="cursor-default">
      <span>{region === Region.US ? "🇺🇸" : "🇬🇧"}</span>
    </span>
  );
}

export const Navbar = () => {
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const { logOut, clinician } = useActiveClinicianContext();
  const { region } = useContext(RegionContext);

  return (
    <nav
      className={
        "flex h-14 flex-shrink-0 items-center justify-between border-b border-gray-300 bg-gray-50 px-4"
      }>
      <div className={"flex items-center gap-2"}>
        <Link href="/courses" passHref legacyBehavior>
          <a
            className={
              "inline-flex cursor-pointer items-center rounded-md bg-gray-50 px-2 py-1 font-medium transition-all duration-200 ease-in-out hover:bg-gray-200"
            }>
            <Logo />
          </a>
        </Link>
        <div className={"flex items-center gap-2 pl-2"}>
          <NavbarLink href={"/courses"}>Courses</NavbarLink>
          <NavbarLink href={"/protocols"}>Protocols</NavbarLink>
          <NavbarLink href={"/interventions"}>Activities</NavbarLink>
          <NavbarLink href={"/psychoeds"}>Educational Materials</NavbarLink>
        </div>
      </div>
      <div className={"flex items-center gap-4"}>
        <RegionDisplay region={region} email={clinician?.email} />
        <DropdownMenu.DropdownMenu open={isDropdownMenuOpen} onOpenChange={setIsDropdownMenuOpen}>
          <DropdownMenu.Trigger>
            <IconButton>
              <Settings strokeWidth={1.5} />
            </IconButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item onSelect={logOut}>Log out</DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.DropdownMenu>
      </div>
    </nav>
  );
};
